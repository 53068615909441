






































import type { PropType } from 'vue';
import { textOverflowMiddle } from '@/helpers/formatString';
import UserIdInput from '@/components/UserIdInput.vue';
export default {
  name: 'OwnUsersList',
  props: {
    users: Array as PropType<string[]>,
    default: (): string[] => []
  },
  components: { UserIdInput },
  data: (): any => ({
    usersInput: [],
    searchPhrase: '',
    invalidIds: [],
    hasErrors: false
  }),
  computed: {
    filtredUsers(): string[] {
      if (!this.searchPhrase) return this.users;
      return this.users.filter((user: string) =>
        user.includes(this.searchPhrase)
      );
    }
  },
  methods: {
    textOverflowMiddle,
    updateUsers(): void {
      if (!this.hasErrors) {
        this.$emit('addUsers', [
          ...new Set([...this.users, ...this.usersInput])
        ]);
        this.clearUsers();
      }
    },
    clearUsers(): void {
      this.usersInput = [];
    },
    clearAndUpdate(): void {
      this.clearUsers();
      this.$emit('addUsers', [...new Set([...this.users])]);
    },
    removeUser(userId: string): void {
      const filterdUserIds = this.users.filter(
        (id: string): boolean => id !== userId
      );
      this.$emit('addUsers', filterdUserIds);
    }
  }
};
