




























































































































import debounce from 'lodash/debounce';
import { getUserList } from '@/api/ClientInfo';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import OwnUsersList from '@/components/users/OwnUsersList.vue';
import type { AxiosError } from 'axios';
import type {
  SelectUsersDialogPayload,
  Users,
  UsersItem,
  UsersRequestParams
} from '@/api/schema';
import type { PropType } from 'vue';

const defaultOptions = {
  page: 1,
  itemsPerPage: 8
};

export default {
  name: 'SelectUsersDialog',
  components: { OwnUsersList },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    maxWidth: {
      type: String as PropType<string>,
      default: (): string => '868px'
    },
    payload: {
      type: Object as PropType<SelectUsersDialogPayload>,
      default: (): SelectUsersDialogPayload => {
        return {
          operatorUserIds: [],
          random: null,
          percentage: null
        } as SelectUsersDialogPayload;
      }
    }
  },
  data: (): any => ({
    newPayload: {
      operatorUserIds: [],
      percentage: null,
      random: null
    },
    randomEnabled: false,
    percentageValues: [25, 50, 75],
    tab: 'all-users',
    views: [
      {
        text: 'All Users',
        value: 'all-users'
      },
      {
        text: 'Own List',
        value: 'own-list'
      },
      {
        text: 'A/B Test',
        value: 'random'
      }
    ],
    operatorUserId: null,
    usersData: [],
    options: { ...defaultOptions },
    total: 0,
    loading: false,
    headers: [
      {
        text: '',
        value: 'checkbox'
      },
      {
        text: 'OPERATOR USER ID',
        value: 'operatorUserId'
      },
      {
        text: 'WALLET ADDRESS',
        value: 'walletHash'
      }
    ],
    selectedHeaders: [
      {
        text: 'OPERATOR USER ID',
        value: 'operatorUserId'
      }
    ],
    itemsPerPage: [10, 20, 50]
  }),
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
        if (!value) {
          this.operatorUserId = null;
          this.newPayload.operatoruserIds = [];
          return;
        }
        this.newPayload.operatorUserIds = [...this.operatorUserIds];
        this.options = { ...defaultOptions };
      }
    },
    currentPage(): number {
      return this.options.page - 1;
    },
    selectedItems(): { operatorUserId: string }[] {
      return this.newPayload.operatorUserIds.map((v) => ({
        operatorUserId: v
      }));
    },
    disableSubmit(): boolean {
      return this.newPayload.random && !this.newPayload.percentage;
    }
  },
  watch: {
    value: {
      handler(show: boolean): void {
        if (show) {
          this.newPayload = { ...this.payload };
          this.options = { ...defaultOptions };
          return;
        }
        this.tab = 'all-users';
        this.operatorUserId = null;
      },
      immediate: true
    },
    options: {
      async handler(): Promise<void> {
        await this.updateUsersList();
      },
      deep: true
    },
    operatorUserId: debounce(function () {
      this.options = { ...defaultOptions };
    }, 500)
  },
  methods: {
    saveAction(): void {
      this.$emit('save', {
        ...this.newPayload,
        operatorUserIds: [...new Set([...this.newPayload.operatorUserIds])]
      });
    },
    closeAction(): void {
      this.$emit('close');
    },
    updateUsersList(): void {
      this.loading = true;
      const params: UsersRequestParams = {
        page: this.currentPage,
        size: this.options.itemsPerPage,
        operatorUserId:
          this.operatorUserId?.length !== 0 ? this.operatorUserId : null
      };
      getUserList(params)
        .then((data: Users): void => {
          const { total, users } = data;
          this.total = total;
          this.usersData = users;
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading = false;
        });
    },
    selectHandler(data: UsersItem): void {
      const { operatorUserId } = data;
      const isAdded =
        this.newPayload.operatorUserIds.includes(operatorUserId);
      if (isAdded) {
        this.newPayload.operatorUserIds =
          this.newPayload.operatorUserIds.filter(
            (id: string) => id !== operatorUserId
          );
      } else {
        this.newPayload.operatorUserIds = [
          ...this.newPayload.operatorUserIds,
          operatorUserId
        ];
      }
    },
    isSelected(data: UsersItem): boolean {
      const { operatorUserId } = data;
      return this.newPayload.operatorUserIds.includes(operatorUserId);
    },
    addOwnUsers(data: string[]): void {
      this.newPayload.operatorUserIds = data;
    },
    clearOwnListHandler(): void {
      if (this.ownListIds) {
        const idsList = this.ownListProcessIds(this.ownListIds);

        let operatorUserIds = this.newPayload.operatorUserIds;

        idsList.forEach((item) => {
          operatorUserIds = operatorUserIds.filter(
            (val) => val !== item.replace(',', '')
          );
        });

        this.$emit('save', { ...this.newPayload, operatorUserIds });
      }
    }
  }
};
